import { createStore, combineReducers } from "redux";
import { applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { i18nextReducer } from "i18next-redux-languagedetector";
import app from "./reducers/app";
import thunk from "redux-thunk";
const middleware = [thunk];
const enhancers = [];
const reducers = {
    i18next: i18nextReducer,
    app,
};

export default function configureStore(initialState) {
    const store = createStore(
        combineReducers(reducers),
        initialState,
        composeWithDevTools(applyMiddleware(...middleware), ...enhancers)
    );
    return store;
}
