import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { setAppLoaded } from "./actions/app";

import Steps from "./components/Steps";
import Form from "./components/Form";

//import "./scss/App.scss";

class App extends Component {
    static propTypes = {
        app: PropTypes.object.isRequired,
        setAppLoaded: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.setAppLoaded();
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <h2>{t("app.headline")}</h2>
                <div className="f2x-app-wrap">
                    <Steps />
                    <Form />
                </div>
            </>
        );
    }
}
const mapStateToProps = (state) => ({ app: state.app });
const mapDispatchToProps = (dispatch) => ({
    setAppLoaded: () => dispatch(setAppLoaded()),
});

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(App);
