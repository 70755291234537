export const APP_LOADED = "APP_LOADED";
export const APP_STEP_1_DONE = "APP_STEP_1_DONE";
export const APP_STEP_2_DONE = "APP_STEP_2_DONE";
export const APP_STEP_3_FIRST = "APP_STEP_3_FIRST";
export const APP_STEP_3_ADD = "APP_STEP_3_ADD";
export const APP_STEP_3_DELETED = "APP_STEP_3_DELETED";
export const APP_STEP_3_REPLACE = "APP_STEP_3_REPLACE";
export const APP_STEP_4_DONE = "APP_STEP_4_DONE";
export const APP_STEP_5_DONE = "APP_STEP_5_DONE";
export const APP_STEP_6_DONE = "APP_STEP_6_DONE";
export const APP_FORM_SHOW = "APP_FORM_SHOW";
export const APP_FORM_SHOW_THANKS = "APP_FORM_SHOW_THANKS";
