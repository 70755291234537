import {
    APP_LOADED,
    APP_STEP_1_DONE,
    APP_STEP_2_DONE,
    APP_STEP_3_FIRST,
    APP_STEP_3_ADD,
    APP_STEP_3_DELETED,
    APP_STEP_3_REPLACE,
    APP_STEP_4_DONE,
    APP_STEP_5_DONE,
    APP_STEP_6_DONE,
    APP_FORM_SHOW,
    APP_FORM_SHOW_THANKS,
} from "./types";

export const setAppLoaded = () => (dispatch) => {
    dispatch({
        type: APP_LOADED,
    });
    return true;
};

export const setAppStep = (step, done, value) => (dispatch) => {
    switch (step) {
        case 1:
            dispatch({
                type: APP_STEP_1_DONE,
                payload: { value, done },
            });
            break;
        case 2:
            dispatch({
                type: APP_STEP_2_DONE,
                payload: { value, done },
            });
            break;
        case 4:
            dispatch({
                type: APP_STEP_4_DONE,
                payload: { value, done },
            });
            break;
        case 5:
            {
                const hasMonth = value.month ? true : false;
                const hasYear = value.year ? true : false;
                let data = {};
                if (hasMonth) data = { ...data, month: value.month };
                if (hasYear) data = { ...data, year: value.year };
                dispatch({
                    type: APP_STEP_5_DONE,
                    payload: { value: data, done },
                });
            }
            break;
        case 6:
            dispatch({
                type: APP_STEP_6_DONE,
                payload: { value, done },
            });
            break;
    }
    return true;
};

export const setAppStepFirst = (step, value) => (dispatch) => {
    switch (step) {
        case 3:
            dispatch({
                type: APP_STEP_3_FIRST,
                payload: value,
            });
            break;
    }
    return true;
};

export const setAppStepAdd = (step, value) => (dispatch) => {
    switch (step) {
        case 3:
            dispatch({
                type: APP_STEP_3_ADD,
                payload: value,
            });
            break;
    }
    return true;
};

export const setAppStepRemove = (step, value) => (dispatch) => {
    switch (step) {
        case 3:
            dispatch({
                type: APP_STEP_3_DELETED,
                payload: value,
            });
            break;
    }
    return true;
};

export const setAppStepReplaceValue =
    (step, oldValue, newValue) => (dispatch) => {
        switch (step) {
            case 3:
                dispatch({
                    type: APP_STEP_3_REPLACE,
                    payload: { oldValue, newValue },
                });
                break;
        }
        return true;
    };

export const showForm = () => (dispatch) => {
    dispatch({
        type: APP_FORM_SHOW,
    });
    return true;
};

export const showThanks = () => (dispatch) => {
    dispatch({
        type: APP_FORM_SHOW_THANKS,
    });
    return true;
};
