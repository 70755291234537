import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Select from "react-select";
import { setAppStep } from "../../actions/app";

class Step5 extends Component {
    static propTypes = {
        current: PropTypes.object.isRequired,
        done: PropTypes.bool.isRequired,
        setAppStep: PropTypes.func.isRequired,
    };
    render() {
        const { t, done, current } = this.props;

        const currentDate = new Date();
        const today = currentDate.getFullYear();
        const endYear = today + 5;
        const optionYear = [];
        for (let y = today; y <= endYear; y++) {
            optionYear.push({ value: y, label: y });
        }

        const currentYear = optionYear.filter((y) => y.value === current.year);
        const currentDateMonth = currentDate.getMonth() + 1;
        const optionMonth = [];

        for (let i = 1; i <= 12; i++) {
            const monthDisabled =
                currentYear &&
                currentYear.length > 0 &&
                today === currentYear[0].value &&
                i < currentDateMonth;

            optionMonth.push({
                value: i,
                label: t(`app.select.month.${i}`),
                isDisabled: monthDisabled,
            });
        }

        const currentMonth = optionMonth.filter(
            (m) => m.value === current.month && !m.isDisabled
        );

        const indicatorSeparator = () => (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7.481"
                height="4.277"
                viewBox="0 0 7.481 4.277"
            >
                <path
                    id="Icon_ionic-ios-arrow-forward"
                    data-name="Icon ionic-ios-arrow-forward"
                    d="M2.988,3.739.157.91a.532.532,0,0,1,0-.755.539.539,0,0,1,.757,0L4.122,3.361a.534.534,0,0,1,.016.737L.917,7.326A.535.535,0,0,1,.159,6.57Z"
                    transform="translate(7.481) rotate(90)"
                    fill="#fff"
                />
            </svg>
        );

        return (
            <div className="f2x-step-item" data-done={done}>
                <span className="f2x-step-item-progress">
                    <span>5/6</span>
                </span>
                <div className="f2x-step-headline">
                    {t("app.step.5.headline")}
                </div>

                <div className="f2x-step-form-group">
                    <Select
                        options={optionYear}
                        value={
                            currentYear && currentYear.length > 0
                                ? currentYear
                                : null
                        }
                        placeholder={t("app.select.placeholder")}
                        onChange={(v) =>
                            this.props.setAppStep(5, true, {
                                year: v.value,
                            })
                        }
                        className="f2x-react-select-container react-select-container"
                        classNamePrefix="f2x-react-select react-select"
                        styles={{
                            indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                            }),
                        }}
                        components={{ DropdownIndicator: indicatorSeparator }}
                        isSearchable={false}
                        tabIndex="5"
                    />
                    <Select
                        options={optionMonth}
                        value={currentMonth.length > 0 ? currentMonth : null}
                        placeholder={t("app.select.placeholder")}
                        onChange={(v) =>
                            this.props.setAppStep(5, true, {
                                month: v.value,
                            })
                        }
                        className="f2x-react-select-container react-select-container"
                        classNamePrefix="f2x-react-select react-select"
                        styles={{
                            indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                            }),
                        }}
                        components={{ DropdownIndicator: indicatorSeparator }}
                        isSearchable={false}
                        tabIndex="6"
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    current: state.app.step_5_value,
    done: state.app.step_5_done,
});
const mapDispatchToProps = (dispatch) => ({
    setAppStep: (step, done, value) => dispatch(setAppStep(step, done, value)),
});

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(Step5);
