import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Select from "react-select";
import {
    setAppStepFirst,
    setAppStepAdd,
    setAppStepRemove,
    setAppStepReplaceValue,
} from "../../actions/app";

class Step3 extends Component {
    static propTypes = {
        values: PropTypes.array.isRequired,
        done: PropTypes.bool.isRequired,
        setAppStepFirst: PropTypes.func.isRequired,
        setAppStepAdd: PropTypes.func.isRequired,
        setAppStepRemove: PropTypes.func.isRequired,
        setAppStepReplaceValue: PropTypes.func.isRequired,
    };

    state = {
        selected: { value: "none", label: "" },
        options: [],
    };

    componentDidMount() {
        const { t } = this.props;
        this.setState({
            options: t("app.step.3.category", { returnObjects: true }),
        });
    }

    onSelectAddBtnClick() {
        const { selected } = this.state;
        this.props.setAppStepAdd(3, selected);
    }

    onSelectDeleteBtnClick(selected) {
        if (selected) {
            this.props.setAppStepRemove(3, selected);
        }
    }

    render() {
        const { t, done, values } = this.props;
        const { options, selected } = this.state;
        const updatedOptions = options.map((option) => {
            for (const selectedOption of values) {
                if (selectedOption && option.value === selectedOption.value) {
                    return { ...option, isDisabled: true };
                }
            }
            return option;
        });

        const indicatorSeparator = () => (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7.481"
                height="4.277"
                viewBox="0 0 7.481 4.277"
            >
                <path
                    id="Icon_ionic-ios-arrow-forward"
                    data-name="Icon ionic-ios-arrow-forward"
                    d="M2.988,3.739.157.91a.532.532,0,0,1,0-.755.539.539,0,0,1,.757,0L4.122,3.361a.534.534,0,0,1,.016.737L.917,7.326A.535.535,0,0,1,.159,6.57Z"
                    transform="translate(7.481) rotate(90)"
                    fill="#fff"
                />
            </svg>
        );

        const FormGroupSelect = ({
            options,
            selected,
            onChange,
            showAdd,
            showDelete,
            selectedDefault,
        }) => {
            return (
                <div className="f2x-step-form-group">
                    <Select
                        options={options}
                        value={selected}
                        placeholder={t("app.select.placeholder")}
                        onChange={onChange}
                        className="f2x-react-select-container react-select-container"
                        classNamePrefix="f2x-react-select react-select"
                        styles={{
                            indicatorSeparator: (provided) => ({
                                ...provided,
                                display: "none",
                            }),
                        }}
                        components={{ DropdownIndicator: indicatorSeparator }}
                        isSearchable={false}
                        tabIndex="3"
                        menuPlacement="top"
                    />
                    {showAdd && (
                        <div
                            className="f2x-step-form-select-btn f2x-step-form-select-btn-add"
                            onClick={() => this.onSelectAddBtnClick()}
                            disabled={selected === null}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13.666"
                                height="13.666"
                                viewBox="0 0 13.666 13.666"
                            >
                                <path
                                    d="M12.69,7.619H8.3V3.226a.976.976,0,0,0-.976-.976H6.345a.976.976,0,0,0-.976.976V7.619H.976A.976.976,0,0,0,0,8.595v.976a.976.976,0,0,0,.976.976H5.369V14.94a.976.976,0,0,0,.976.976h.976A.976.976,0,0,0,8.3,14.94V10.547H12.69a.976.976,0,0,0,.976-.976V8.595A.976.976,0,0,0,12.69,7.619Z"
                                    transform="translate(0 -2.25)"
                                    fill="#fff"
                                />
                            </svg>
                        </div>
                    )}

                    {showDelete && (
                        <div
                            className="f2x-step-form-select-btn f2x-step-form-select-btn-delete"
                            onClick={() => {
                                const removeItem = selected
                                    ? selected
                                    : selectedDefault;
                                this.onSelectDeleteBtnClick(removeItem);
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13.666"
                                viewBox="0 0 448 512"
                            >
                                <path
                                    d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                                    fill="#fff"
                                />
                            </svg>
                        </div>
                    )}
                </div>
            );
        };

        const selectedValue = values.length > 0 && values[0]!=="none" ? values[0]: null;

        return (
            <div className="f2x-step-item" data-done={done}>
                <span className="f2x-step-item-progress">
                    <span>3/6</span>
                </span>
                <div className="f2x-step-headline">
                    {t("app.step.3.headline")}
                </div>

                <FormGroupSelect
                    options={updatedOptions}
                    selected={selectedValue}
                    selectedDefault={selected}
                    onChange={(value) => {
                        this.props.setAppStepFirst(3, value);
                    }}
                    showAdd={values.length > 1?false:true}
                    showDelete={false}
                />

                {values.map((item, key) => {
                    if (key === 0) return null;

                    const selectedOption = item.value === "none" ? null : item;

                    return (
                        <FormGroupSelect
                            key={key}
                            options={updatedOptions}
                            selected={selectedOption}
                            selectedDefault={selected}
                            onChange={(value) => {
                                this.props.setAppStepReplaceValue(
                                    3,
                                    item,
                                    value
                                );
                            }}
                            showAdd={values.length > (key+1)? false:true}
                            showDelete={true}
                        />
                    );
                })}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    values: state.app.step_3_value,
    done: state.app.step_3_done,
});
const mapDispatchToProps = (dispatch) => ({
    setAppStepFirst: (step, value) => dispatch(setAppStepFirst(step, value)),
    setAppStepAdd: (step, value) => dispatch(setAppStepAdd(step, value)),
    setAppStepRemove: (step, value) => dispatch(setAppStepRemove(step, value)),
    setAppStepReplaceValue: (step, oldValue, newValue) =>
        dispatch(setAppStepReplaceValue(step, oldValue, newValue)),
});

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(Step3);
