import React from "react";
import ReactDOM from "react-dom/client";

import { Provider } from "react-redux";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { I18nextProvider } from "react-i18next";
import { languageChange } from "i18next-redux-languagedetector";

import configureStore from "./redux/store";
import configureI18n from "./i18n";

const i18nextConfig = {
    language: null,
    whitelist: ["en", "de"],
    ns: ["common"],
    defaultNS: "common",
};

const store = configureStore({
    i18next: i18nextConfig,
});

const i18n = configureI18n({
    i18nextConfig,
    redux: {
        lookupRedux: function () {
            return store.getState().i18next;
        },
        cacheUserLanguageRedux: function (language) {
            store.dispatch(languageChange(language));
        },
    },
});

const appElemen = document.getElementById("f2x-contact-app");
if (appElemen) {
    const root = ReactDOM.createRoot(
        document.getElementById("f2x-contact-app")
    );
    root.render(
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <App />
            </I18nextProvider>
        </Provider>
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
}
