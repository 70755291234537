import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import HubspotContactForm from "./HubspotContactForm";

class Form extends Component {
    static propTypes = {
        app: PropTypes.object.isRequired,
    };

    render() {
        const { t, app } = this.props;

        return (
            <div className={`f2x-form ${app.showForm ? "f2x-visible" : ""}`}>
                {app.showForm && !app.showThanks && (
                    <>
                        <h3>{t("app.form.subline")}</h3>
                        <HubspotContactForm />
                    </>
                )}
                {app.showThanks && (
                    <>
                        <h3>{t("app.form.thanks.headline")}</h3>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: t("app.form.thanks.content"),
                            }}
                        ></p>
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({ app: state.app });
const mapDispatchToProps = () => ({});

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(Form);
