import {
    APP_LOADED,
    APP_STEP_1_DONE,
    APP_STEP_2_DONE,
    APP_STEP_3_FIRST,
    APP_STEP_3_ADD,
    APP_STEP_3_DELETED,
    APP_STEP_3_REPLACE,
    APP_STEP_4_DONE,
    APP_STEP_5_DONE,
    APP_STEP_6_DONE,
    APP_FORM_SHOW,
    APP_FORM_SHOW_THANKS,
} from "../../actions/types";

const initialState = {
    loaded: false,
    step_1_value: 0,
    step_1_done: false,
    step_2_value: 0,
    step_2_done: false,
    step_3_value: [],
    step_3_done: false,
    step_4_value: 0.0,
    step_4_done: false,
    step_5_value: {
        month: "",
        year: "",
    },
    step_5_done: false,
    step_6_value: { value: "", label: "" },
    step_6_done: false,
    showForm: false,
    showThanks: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case APP_LOADED:
            return {
                ...state,
                loaded: true,
            };
        case APP_STEP_1_DONE:
            return {
                ...state,
                step_1_value: payload.value,
                step_1_done: payload.done,
            };
        case APP_STEP_2_DONE:
            return {
                ...state,
                step_2_value: payload.value,
                step_2_done: payload.done,
            };

case APP_STEP_3_FIRST:
                return {
                    ...state,
                    step_3_value: state.step_3_value.length > 0 ? [
                        action.payload,
                        ...state.step_3_value.slice(1) 
                    ] : [action.payload],
                    step_3_done: true,
                };
                case APP_STEP_3_ADD:
            return {
                ...state,
                step_3_value: [...state.step_3_value, action.payload],
                step_3_done: true,
            };
        case APP_STEP_3_REPLACE: {
            let step_3_value = state.step_3_value.filter(
                (i) => i.value !== action.payload.oldValue.value
            );
            step_3_value = [...step_3_value, action.payload.newValue];

            return {
                ...state,
                step_3_value: step_3_value,
                step_3_done: step_3_value.length > 0,
            };
        }

        case APP_STEP_3_DELETED: {
            const step_3_value = state.step_3_value.filter(
                (i) => i.value !== action.payload.value
            );

            return {
                ...state,
                step_3_value: step_3_value,
                step_3_done: step_3_value.length > 0,
            };
        }
        case APP_STEP_4_DONE:
            return {
                ...state,
                step_4_value: payload.value,
                step_4_done: payload.done,
            };
        case APP_STEP_5_DONE: {
            const hasMonth = payload.value.month ? true : false;
            const hasYear = payload.value.year ? true : false;

            let step_5_value = state.step_5_value;
            if (hasMonth)
                step_5_value = { ...step_5_value, month: payload.value.month };
            if (hasYear)
                step_5_value = { ...step_5_value, year: payload.value.year };

            if (step_5_value.year && step_5_value.month) {
                const currentDate = new Date();
                const currentDateYear = currentDate.getFullYear();
                const currentDateMonth = currentDate.getMonth() + 1;
                if (
                    step_5_value.year === currentDateYear &&
                    step_5_value.month < currentDateMonth
                )
                    step_5_value = { ...step_5_value, month: "" };
            }

            return {
                ...state,
                step_5_value: step_5_value,
                step_5_done:
                    step_5_value.month && step_5_value.year ? true : false,
            };
        }
        case APP_STEP_6_DONE:
            return {
                ...state,
                step_6_value: payload.value,
                step_6_done: payload.done,
            };
        case APP_FORM_SHOW:
            return {
                ...state,
                showForm: true,
            };
        case APP_FORM_SHOW_THANKS:
            return {
                ...state,
                showThanks: true,
            };
        default:
            return state;
    }
}
