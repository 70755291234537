import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";

import { setAppStep } from "../../actions/app";

class Step1 extends Component {
    static propTypes = {
        value: PropTypes.number.isRequired,
        done: PropTypes.bool.isRequired,
        setAppStep: PropTypes.func.isRequired,
    };

    state = {
        min: 0,
        max: 250000,
        step: 1000,
    };

    render() {
        const { t, done, value, setAppStep } = this.props;
        const { min, max, step } = this.state;

        return (
            <div className="f2x-step-item" data-done={done}>
                <span className="f2x-step-item-progress">
                    <span>1/6</span>
                </span>
                <div className="f2x-step-headline">
                    {t("app.step.1.headline")}
                </div>

                <div className="f2x-step-form-group">
                    <label>{t("app.step.1.label")}</label>
                    <input
                        type="number"
                        value={value > 0 && value}
                        onChange={(e) => {
                            const value = e.target.value
                                ? parseInt(e.target.value)
                                : 0;
                            setAppStep(1, true, value);
                        }}
                        tabIndex="1"
                    />
                </div>
                <div
                    className="f2x-step-form-range"
                    data-show-current={
                        value > min + 160000 && value < max - 500000
                    }
                >
                    <InputRange
                        minValue={min}
                        maxValue={max}
                        value={value}
                        step={step}
                        onChange={(value) =>
                            setAppStep(1, true, parseInt(value))
                        }
                        onChangeComplete={() => {}}
                        classNames={{
                            activeTrack:
                                "input-range__track input-range__track--active",
                            disabledInputRange:
                                "f2x-step-form-input-range input-range input-range--disabled",
                            inputRange: "f2x-step-form-input-range input-range",
                            labelContainer: "input-range__label-container",
                            maxLabel:
                                "input-range__label input-range__label--max",
                            minLabel:
                                "input-range__label input-range__label--min",
                            slider: "input-range__slider",
                            sliderContainer: "input-range__slider-container",
                            track: "input-range__track input-range__track--background",
                            valueLabel:
                                "input-range__label input-range__label--value",
                        }}
                        formatLabel={(value) => value.toLocaleString("de-DE")}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    value: state.app.step_1_value,
    done: state.app.step_1_done,
});
const mapDispatchToProps = (dispatch) => ({
    setAppStep: (step, done, value) => dispatch(setAppStep(step, done, value)),
});

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(Step1);
