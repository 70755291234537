import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { showForm } from "../actions/app";

import Step1 from "./Step/Step1";
import Step2 from "./Step/Step2";
import Step3 from "./Step/Step3";
import Step4 from "./Step/Step4";
import Step5 from "./Step/Step5";
import Step6 from "./Step/Step6";

class Steps extends Component {
    static propTypes = {
        app: PropTypes.object.isRequired,
        showForm: PropTypes.func.isRequired,
    };
    onButtonClick() {
        const { app, showForm } = this.props;
        const btnActive =
            app.step_1_done &&
            app.step_2_done &&
            app.step_3_done &&
            app.step_4_done &&
            app.step_5_done &&
            app.step_6_done;

        if (btnActive) {
            showForm();
        }
    }

    render() {
        const { t, app } = this.props;
        const btnActive =
            app.step_1_done &&
            app.step_2_done &&
            app.step_3_done &&
            app.step_4_done &&
            app.step_5_done &&
            app.step_6_done;

        return (
            <div className={`f2x-steps ${app.showForm ? "f2x-hidden" : ""}`}>
                <h3>{t("app.steps.subline")}</h3>
                <div className="f2x-row">
                    <div className="f2x-col">
                        <Step1 />
                    </div>
                    <div className="f2x-col">
                        <Step4 />
                    </div>
                </div>
                <div className="f2x-row">
                    <div className="f2x-col">
                        <Step2 />
                    </div>
                    <div className="f2x-col">
                        <Step5 />
                    </div>
                </div>
                <div className="f2x-row">
                    <div className="f2x-col">
                        <Step3 />
                    </div>
                    <div className="f2x-col">
                        <Step6 />
                    </div>
                </div>
                <div className="f2x-steps-btns">
                    <button
                        className="f2x-form-btn"
                        onClick={() => this.onButtonClick()}
                        disabled={!btnActive}
                    >
                        {t("app.steps.btn")}
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({ app: state.app });
const mapDispatchToProps = (dispatch) => ({
    showForm: () => dispatch(showForm()),
});

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(Steps);
